var cars = require('./base/pages.json');

/**
 * Adds markup with hot advertisement links into container.
 * 
 * @requires module:base/pages // Requires cars database in js/json
 * @param {Number} n Quantity of offers to add
 * @returns {String} HTML markup of hot offers
 * 
 * @example
 * document.getElementByID('hot-offers').innerHTML = fillHotOffers(4) // adds 4 advertisement links 
 * 
 * @author Alex Naidovich for Truck1
 */
function fillHotOffers(n) {
  const limit = cars.length - 1;
  const used = []

  function getRandoms(arr) {
    const random = Math.floor(Math.random() * limit);
    for (let i = 0; i < arr.length; i++) {
      if (random === arr[i]) return getRandoms(arr);
    }
    return random;
  }
  for (let i = 0; i < n; i++) {
    used.push(getRandoms(used));
  }

  return used.reduce((markup, i) => {
    return markup += `
      <div class="__ad">
        <div class="container">
          <a href="./${cars[i].filename.replace('.ejs', '')}.html">
            <img src="/assets/images/${cars[i].srcs[0].split('-')[0]}.jpg" alt="${cars[i].name}" style="max-width: 100%;max-height: 100%;">
          </a>

          <a href="./${cars[i].filename.replace('.ejs', '')}.html"><h4>${cars[i].name}</h4></a>

          <span class="price">${typeof cars[i].price === 'string' ? cars[i].price : cars[i].price[0]}</span>
        </div>
      </div>
    `;
  }, ``);
}

(() => {
  if (typeof module !== 'undefined' && module.exports) {
    module.exports = fillHotOffers;
  } else if (typeof define === 'function' && define.AMD) {
    define('fillHotOffers', fillHotOffers);
  } else {
    window.fillHotOffers = fillHotOffers;
  }
})()